@mixin main-font {
	font-size: 1.71em;

	@media ( max-width: 1950px ) {
		font-size: 1.28em;
	}

	@media ( max-width: 1660px ) { 
		font-size: 1.14em;
	}

	@media ( max-width: 1440px ) {
		font-size: 1em;
	}
}

@mixin footer-heading {
	font-size: 2.28em;
	
	@media (max-width: 1950px ) {
		font-size: 1.71em;
	}

	@media ( max-width: 1600px ) {
		font-size: 1.32em;
	}
}

@mixin heading-font {
	font-size: 2.28em;

	@media ( max-width: 1950px ) {
		font-size: 1.71em;
	}

	@media ( max-width: 1660px ) {
		font-size: 1.42em;
	}

	@media ( max-width: 1440px ) {
		font-size: 1.14em;
	}
}

@mixin single-content {
	font-size: 2em;

	@media ( max-width: 1950px ) {
		font-size: 1.28em;
	}

	@media ( max-width: 1660px ) { 
		font-size: 1.14em;
	}

	@media ( max-width: 1440px ) {
		font-size: 1em;
	}
}