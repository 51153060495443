$yellow: #ffd401;

body.contrast {
	background: #000;
	color: $yellow;

	.has-scrollbar {
		scrollbar-color: $yellow #000;
	
		&::-webkit-scrollbar-track {
			background: #000;
		}
		
		&::-webkit-scrollbar-thumb {
			background-color: $yellow;
		}
	}

	svg {
		&.stroke {
			* { stroke: $yellow !important; }
		}

		&.with-lines {
			path:not(.lines ) { fill: $yellow !important; }
			path.lines { fill: #000 !important; }
		}

		&.fill {
			* { fill: $yellow !important; }
		}

		&.fill.doc {
			*:not(.white) { fill: $yellow !important; }
			*.white { fill: #000 !important; }
		}

		&.stroke-fill {
			*.stroke { stroke: $yellow !important; }
			*.fill { fill: $yellow !important; }
		}

		&.mail-button {
			rect { fill: #000 !important; }
			path:not(.stroke) { fill: $yellow !important; }
			path.stroke { stroke: $yellow !important; }
		}
	}
}