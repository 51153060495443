@import "./colors.scss";
@import "./font-sizes.scss";

* {
	box-sizing: border-box;
}

html, body, #eto-app {
	height: 100%;
}

body {
	font-size: 14px;
	margin: 0;
	font-family: 'Overpass', sans-serif;
	background: #F7F8FC;
}

#eto-app {
	display: flex;
	flex-direction: column;
}

main {
	flex: auto;

	&.web {
		overflow: unset;
	}

	&.touch {
		overflow: auto;
	}
}

img, svg {
	max-width: 100%;
	height: auto;
}

a {
	text-decoration: none;
	color: $dark_blue;
}

.has-scrollbar {
	scrollbar-width: thin;
	scrollbar-color: $dark_blue $quartz;

	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}
	
	&::-webkit-scrollbar-track {
		background: $quartz;
	}
	
	&::-webkit-scrollbar-thumb {
		background-color: $dark_blue;
		outline: none;
	}
}

.popup-back {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	max-width: 100vw;
	height: 100%;
	background-color: rgba(0, 0, 0, .8);
	z-index: 999;

	overflow: auto;
}

.show-mail-button {
	cursor: pointer;
}


.d-none {
	display: none;
}

.df-ac {
	display: flex;
	align-items: center;
}

.df-column {
	display: flex;
	flex-direction: column;
}

.df-centered {
	display: flex;
	align-items: center;
	justify-content: center;
}

.row {
	display: flex;
	flex-wrap: wrap;
}


.container {
	max-width: 1797px;
	margin: 0 auto;
	padding: 0 15px;

	@media ( max-width: 1950px ) {
		max-width: 1520px;
	}

	@media ( max-width: 1660px ) {
		max-width: 1200px;
	}

	@media ( max-width: 1100px ) {
		max-width: 100%;
		padding: 0 30px;
	}

	@media ( max-width: 768px ) {
		padding: 0 15px;
	}
}