@import "styles/main/colors.scss";
@import "styles/main/font-sizes.scss";

.footer {
	color: #fff;
	font-family: 'Roboto', sans-serif;

	a {
		color: #fff;
	}

	.container {
		max-width: 1797px;
	}
}

.footer__top_web {
	margin-top: 50px;
	height: 466px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50%;
	
	display: flex;
	padding-bottom: 43px;

	> div {
		width: 100%;
		margin-top: auto;
	}

	h2 {
		margin: 0 15px 0 0;
		font-weight: bold;
		display: flex;
		align-items: center;

		@include footer-heading;

		svg {
			max-height: 37px;
			margin-right: 14px;
		}
	}
}

.footer__top_web-contacts {
	margin-left: auto;
	display: flex;
	font-size: 1.14em;

	@media (max-width: 1660px){
		font-size: 1em;
	}

	a {
		margin-left: 20px;

		svg {
			width: 31px;
			min-width: 31px;
			margin-right: 10px;

			@media (max-width: 1660px){
				min-width: 20px;
				max-width: 20px;
				margin-right: 8px;
			}
		}
	}
}

.footer__top {
	background: $dark_blue;
	padding: 20px 0;

	.container {
		position: relative;
	}
}

.footer__nav {

	justify-content: space-between;

	.close-button {
		position: absolute;
		z-index: 1000;
		right: 0;
	}
	
	a {
		font-size: 1.28em;
		color: #fff;
		text-align: center;
		margin: 0 40px;
		text-transform: uppercase;

		span {
			width: 55px;
			height: 55px;
			margin: 0 auto 10px;

			display: flex;
			align-items: center;
			justify-content: center;
			background: $turquoise;
			border-radius: 10px;
			
			svg {
				max-width: 65%;
				height: auto;
			}
		}
	}
}


.footer__bottom {
	background: $dark;
	height: auto;
	padding: 10px 0 7px;
	
	span {
		margin-right: 22px;
	}

	> div {
		svg:last-child {
			margin-left: 9px;
		}
	}
}

body.contrast {
	.footer__top_web,
	.footer a,
	.footer__bottom span {
		color: $yellow;
	}

	.footer__nav a,
	.footer__top {
		background: #000;
		border-color: $yellow;
		color: $yellow;
	}

	.footer__nav a span {
		background: #000;
	}

	.footer__bottom {
		background-color: #1a1a1a;
	}

	.footer__top {
		border-top: 1.5px solid $yellow;
	}

	.footer__nav a span {
		border: 1.5px solid $yellow;
	}

	.footer__top_web {
		filter: grayscale(1);
	}
}

@media ( max-width: 1950px ) {  
	.footer__nav a {
		span {
			width: 50px;
			height: 50px;
		}
	}
}


@media ( max-width: 1660px ) { 
	.footer__nav a {
		font-size: 1.14em;

		span {
			width: 50px;
			height: 50px;
		}
	}

	.footer__top {
		height: auto;
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

@media ( max-width: 1440px ) {
	.footer__nav a {
		font-size: 1em;

		span {
			width: 44px;
			height: 44px;
		}
	}
}

@media ( max-width: 1100px ) {
	.footer__top {
		display: none;
	}
}

@media ( max-width: 1150px ) {
	.footer__top_web {
		background: $dark_blue;
		background-image: none !important;
		padding-top: 28px;
		padding-bottom: 24px;
		height: auto;

		.container {
			flex-wrap: wrap;

			h2 {
				width: 100%;
			}
		}
	}

	.footer__top_web-contacts {
		margin-left: 0;
		margin-top: 10px;
		flex-wrap: wrap;

		a {
			width: 100%;
			margin: 10px 0;
		}
	}
}