
$dark: #040B13;
$light_blue: #2D7392;
$blue: #4D6989;
$metallic_blue: #274F75;
$dark_blue: #0B2640;
$gray: #8795A1;
$turquoise: #5FB4D9;
$fiord: #46596A;

$quartz: #DFE0EB;
$alice_blue: #ECF3F6;
$turquoise_gray: #D8E4E9;

$brand_primary: #0B2640;
$brand_secondary: #3E9EC8;
$black_pearl: #001528;
$neutrals_black_60: #A6AEBF;
$wedgewood: #4D6989;
$black: #23242A;
$bleau: #DFE0EB;

$yellow: #ffd401;
$yellow_20: #CCAA01;
$yellow_40: #A38801;
$yellow_60: #826D01;
$yellow_80: #685701;
$yellow_100: #534601;
